<template>
  <div v-if="options.content">
    <v-card flat class="px-0 py-4" style="min-height: 40px">
      <TipTapReader class="tiptap_style" :options="options" v-if="options.content" :key="componentKey" />
      <!-- <div v-else class="text-center font-weight-medium">입력 정보가 없습니다.</div> -->
    </v-card>
  </div>
</template>
<script>
import TipTapReader from "@/components/tiptap/Reader"
import htmlServce from "@/api/html-service"
import { mapState } from "vuex"
export default {
  components: {
    TipTapReader,
  },
  props: ["id"],
  data: () => ({
    //TipTap
    options: {
      content: "-",
      editable: false,
      supportImage: true,
      supportVideo: true,
    },
    componentKey: 0,

    htmlResource: null,
  }),
  mounted() {
    this.load()
  },
  computed: {
    ...mapState(["lang"]),
  },
  watch: {
    lang: function (val) {
      this.options.content = this.getContent()
      this.componentKey += 1
    },
  },
  methods: {
    load() {
      if (!this.id) {
        return
      }
      htmlServce
        .getHtmlBlobData(this.id)
        .then((res) => {
          if (res) {
            //this.options.content = res.content
            this.htmlResource = res
            this.options.content = this.getContent()
            this.componentKey += 1
          }
        })
        .catch((e) => {
          console.log(e)
          alert(this.$t("alert.error_failed_get_information"))
        })
    },
    getContent() {
      if (!this.htmlResource) {
        return ""
      }
      switch (this.lang) {
        case "jp":
          return this.htmlResource.content_jp || this.htmlResource.content
        case "en":
          return this.htmlResource.content_en || this.htmlResource.content
        default:
          return this.htmlResource.content
      }
    },
  },
}
</script>
<style></style>
