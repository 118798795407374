<template>
  <div class="container d-flex flex-column">
    <!-- -->
    <div class="content flex-grow-1">
      <TipTapReader class="tiptap_style pa-0" :options="options" :key="componentKey + 'home'" />
    </div>
    <!-- <div class="bottom">
      <div class="d-flex justify-center">
        <v-btn text small to="/policy/rule?type=read" style="color: rgb(141, 141, 141)"> {{ $t("bottom.use_terms") }} </v-btn>
        <span style="color: rgb(141, 141, 141)">&nbsp;/&nbsp;</span>
        <v-btn class="font-weight-bold" text small to="/policy/privacy?type=read" style="color: rgb(141, 141, 141)"> {{ $t("bottom.private_terms") }}</v-btn>
      </div>
      <Bottom />
    </div> -->
    <InappBrowserWarningDialog @input="(e) => (show_warning_dialog = e)" @confirm="show_warning_dialog = false" :visible="show_warning_dialog" />
  </div>
</template>
<script>
import Bottom from "./components/Bottom.vue"
import InappBrowserWarningDialog from "@/views/components/InappBrowserWarningDialog"
import QrloginComplDialog from "@/views/components/QrcodeLoginCompleteDialog"
import { mapState, mapGetters, mapMutations } from "vuex"
import TipTapReader from "@/components/tiptap/Reader"
import htmlService from "../api/html-service"

export default {
  components: {
    Bottom,
    InappBrowserWarningDialog,
    QrloginComplDialog,
    TipTapReader,
  },

  created() {},

  data: () => ({
    show_warning_dialog: false,
    list: [],

    //TipTap
    options: {
      content: "",
      editable: true,
      supportImage: true,
      supportVideo: true,
    },
    componentKey: 0,

    htmlResource: null,
  }),

  mounted() {
    // 목록 불러오기
    // this.load()
    if (this.$route.query.qrlogin === "true") {
      this.qrlogin_compl_dialog = true
    } else if (!this.$store.state.lafia_user.is_logined) {
      // 비로그인 사용자만
      console.log("isShowinappBrowserWarning", this.$store.state.isShowinappBrowserWarning)
      this.show_warning_dialog = !this.$store.state.isShowinappBrowserWarning
      if (this.show_warning_dialog) {
        this.$store.commit("SET_INAPPBROWER_STATE", true)
      }
    }

    let lang = this.$route.query.lang
    if (lang && ["ko", "en", "jp"].includes(lang)) {
      this.SET_LANG(lang)
    }

    this.load()
  },

  computed: {
    ...mapState(["lang"]),

    mainTitleLogoFileName() {
      switch (this.lang) {
        case "ko":
          return "sela-main-title"
        case "jp":
          return "sela-main-title-jp"
        default:
          return "sela-main-title-en"
      }
    },
  },
  watch: {
    lang() {
      this.options.content = this.getContent()
      this.componentKey++
    },
  },

  methods: {
    ...mapMutations(["SET_LANG"]),
    // 목록 불러오기
    load() {
      htmlService.getHtmlBlobData("home").then((res) => {
        this.htmlResource = res
        this.options.content = this.getContent()
        this.componentKey++
      })
    },
    getContent() {
      if (!this.htmlResource) {
        return ""
      }
      switch (this.lang) {
        case "jp":
          return this.htmlResource.content_jp || this.htmlResource.content
        case "en":
          return this.htmlResource.content_en || this.htmlResource.content
        default:
          return this.htmlResource.content || ""
      }
    },
  },
}
</script>

<style scoped>
.container {
  position: relative;
  height: calc(100% - 80px - 56px);
  background-color: white;
  margin: 0;
  padding: 0;
}
.content {
}

.bottom {
  width: 100%;
  height: 170px;
  background-color: #231232;
  border-top: 1px solid #c2bcba;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
