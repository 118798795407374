<template>
  <div>
    <HtmlReader id="castle-conceptbooth" />
    <TitleLogo :title="$t('page.conceptbooth.title')" />
    <!-- <v-sheet>
      <iframe width="100%" style="aspect-ratio: 16/9" src="https://www.youtube.com/embed/wXfhoq4ZNuM?si=QXysTo3fsbrPyBAM" frameborder="0" allowfullscreen></iframe>
    </v-sheet> -->

    <v-sheet>
      <v-tabs v-model="tabs" color="primary" fixed-tabs class="mt-1">
        <v-tab v-for="item in tabItems" :key="item.id" class="text-subtitle-1 font-weight-medium">
          {{ $t(item.title) }}
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>

      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <ConceptBooth />
        </v-tab-item>
        <!-- <v-tab-item>
          <HtmlReader id="castle-selfie" />
        </v-tab-item>
        <v-tab-item>
          <HtmlReader id="castle-dessert" />
        </v-tab-item> -->
        <v-tab-item>
          <CastleQna />
        </v-tab-item>
      </v-tabs-items>
    </v-sheet>
  </div>
</template>
<script>
import TitleLogo from "@/views/components/TitleLogo"
import ConceptBooth from "./components/ConceptBooth"
import HtmlReader from "./components/HtmlReader"
import CastleQna from "./components/CastleQna"

const CASTLE_TABMENU = [
  {
    id: "conceptbooth",
    title: "page.conceptbooth.tab-title",
  },
  // {
  //   id: "selfie",
  //   title: "셀카존",
  // },
  // {
  //   id: "dessert",
  //   title: "디저트",
  // },
  {
    id: "preserve-qna",
    title: "QNA",
  },
]

export default {
  components: {
    TitleLogo,
    ConceptBooth,
    HtmlReader,
    CastleQna,
  },
  data: () => ({
    tabs: null,
    tabItems: CASTLE_TABMENU,
  }),
}
</script>
<style scoped>
.v-tab--active {
  color: black;
}
</style>
